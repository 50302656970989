/* eslint-disable array-callback-return */
import React from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import PortableText from 'react-portable-text';
import SEO from '../components/layout/SEO';
import Breadcrumb from '../components/layout/Breadcrumb';
import SidebarLinks from '../components/widgets/SidebarLinks';
import SidebarContact from '../components/widgets/sidebarContact';
import TeamMember from '../components/home/TeamMember';

const AboutStyles = styled.div`
  .content {
    padding: 60px 0;
    display: grid;
    grid-template-columns: 3.5fr 1fr;
    grid-gap: 50px;
  }

  @media only screen and (max-width: 780px) {
    .content {
      grid-template-columns: 1fr;
      grid-auto-flow: dense;
    }
  }

  .hr {
    width: 10%;
    background-color: var(--accent);
    height: 2px;
    margin-bottom: 30px;
  }

  .main-content {
    h2 {
      margin-top: 0;
    }
    p {
      border-bottom-color: rgb(102, 102, 102);
      border-bottom-style: none;
      border-bottom-width: 0px;
      border-image-outset: 0;
      border-image-repeat: stretch;
      border-image-slice: 100%;
      border-image-source: none;
      border-image-width: 1;
      border-left-color: rgb(102, 102, 102);
      border-left-style: none;
      border-left-width: 0px;
      border-right-color: rgb(102, 102, 102);
      border-right-style: none;
      border-right-width: 0px;
      border-top-color: rgb(102, 102, 102);
      border-top-style: none;
      border-top-width: 0px;
      box-sizing: border-box;
      color: rgb(102, 102, 102);
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
      font-feature-settings: normal;
      font-kerning: auto;
      font-language-override: normal;
      font-optical-sizing: auto;
      font-size: 16px;
      font-size-adjust: none;
      font-stretch: 100%;
      font-style: normal;
      font-variant: normal;
      font-variant-alternates: normal;
      font-variant-caps: normal;
      font-variant-east-asian: normal;
      font-variant-ligatures: normal;
      font-variant-numeric: normal;
      font-variant-position: normal;
      font-variation-settings: normal;
      font-weight: 400;
      line-height: 28px;
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 27px;
      outline-color: rgb(102, 102, 102);
      outline-style: none;
      outline-width: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 0px;
      text-rendering: optimizelegibility;
      vertical-align: baseline;
      -moz-osx-font-smoothing: grayscale;
    }
    img {
      width: 100%;
    }
    .gatsby-image-wrapper {
      margin-top: 30px;
    }
  }

  .sidebar {
    .links {
      background: #414141;
      padding: 20px;
    }
    p {
      margin: 0;
    }
  }

  .teams {
    padding-top: 30px;
  }
`;

export default function AboutPage({ data, location }) {
  console.log(data);
  const img = data.sanityTeam.image
    ? getImage(data?.sanityTeam?.image?.asset)
    : '';
  return (
    <AboutStyles>
      <SEO title="Our Team" location={location.href} />
      <Breadcrumb title="Leadership Team">
        <li>
          <Link to="/">Home</Link>
        </li>

        <li>
          <Link to="/about">About Us</Link>
        </li>

        <li>
          <Link to="/team">Leadership Team</Link>
        </li>
      </Breadcrumb>

      <div className="container content flex">
        <div className="main-content">
          <h2>Leadership Team</h2>

          <div className="hr" />

          <PortableText content={data.sanityTeam.body._rawEn} />

          {data.sanityTeam.image ? (
            <GatsbyImage image={img} alt="about us" />
          ) : (
            ''
          )}

          {/* need to check if the member is active or not and then render them out  */}

          <div className="teams">
            {data.sanityTeam.members.map((member) => {
              if (member.active)
                return <TeamMember member={member} key={member._key} />;
            })}
          </div>
        </div>

        <div className="sidebar">
          <SidebarLinks>
            <li>
              <Link to="/about">About Us</Link>
            </li>

            <li>
              <Link to="/delivery">Delivery Centres</Link>
            </li>

            <li>
              <Link to="/sustainable">
                Sustainable Procurement and Delivery
              </Link>
            </li>

            <li>
              <Link to="/team">Leadership Team</Link>
            </li>
          </SidebarLinks>

          <SidebarContact />
        </div>
      </div>
    </AboutStyles>
  );
}

export const query = graphql`
  {
    sanityTeam {
      body {
        _rawEn
      }
      image {
        asset {
          gatsbyImageData
        }
      }

      members {
        _key
        active
        bio
        name
        role
        image {
          asset {
            url
          }
        }
      }
    }
  }
`;

AboutPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};
