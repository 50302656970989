import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { BsPersonCircle } from 'react-icons/bs';

const TeamMemberStyles = styled.article`
  background: whitesmoke;
  margin-bottom: 10px;
  padding: 30px;

  display: grid;
  grid-template-columns: 110px 1fr;
  grid-gap: 30px;

  svg {
    color: var(--accent);
    width: 110px;
    height: 110px;
  }

  .avatar {
    width: 110px;
    height: 110px;
    background-size: cover;
    background-position: center;

    img {
      max-width: 100%;
    }
  }

  h6 {
    font-size: 16px;
    margin: 0;
    padding-bottom: 15px;

    span {
      color: #999;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      &::before {
        content: '|';
        padding: 0 10px;
        color: #999;
      }
      p {
        display: inline-block;
        font-size: 14px;
      }
    }
  }

  p {
    margin: 0px !important;
    padding: 0px;
  }

  .socials {
    padding-top: 15px;

    .icon:hover {
      svg {
        fill: var(--burnt);
        cursor: pointer;
      }
    }

    svg {
      font-size: 14px;
      background: white;
      padding: 2px;
      margin-right: 5px;
      fill: #898989;
    }
  }

  @media only screen and (max-width: 500px) {
    grid-template-columns: 1fr;

    h6 {
      span {
        display: block;
        &::before {
          content: '';
          padding: 0;
          display: none;
        }
        p {
          display: block;
        }
      }
    }
  }
`;

export default function TeamMember({ member }) {
  // if no user image is uploaded for the member, then fetch the image from pexels.  This needs updating to a proper placeholder
  const imageURL = member.image
    ? member.image.asset.url
    : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1331&q=80';

  return (
    <TeamMemberStyles>
      {/* <div
        className="avatar"
        style={{ backgroundImage: `url('${imageURL}')` }}
      /> */}

      {member.image ? (
        <div
          className="avatar"
          style={{ backgroundImage: `url('${imageURL}')` }}
        />
      ) : (
        <BsPersonCircle />
      )}

      <div className="member-info">
        <h6>
          {member.name}{' '}
          <span className="role">
            <p>{member.role}</p>
          </span>
        </h6>

        {member.bio ? <p>{member.bio}</p> : ''}
      </div>
    </TeamMemberStyles>
  );
}

TeamMember.propTypes = {
  member: PropTypes.object.isRequired,
};
